/// Init
import React from "react";

// Importing Components
import Header from "../components/Header";
// Home Component

export default function Home() {
  return (
    <div>
      <Header/>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
  Container
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2'
import logo from '../assets/logo/NYC.png'

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  background:  'black',
  borderRadius: '10px',
  color:'white',
  // boxShadow: '1 px 3px 5px gray',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', 
}));


const StyledForm = styled('form')(({ theme }) => ({
  padding: theme.spacing(0),
  
}));




const StyledSubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background:  '#394867',
  borderRadius:'25px',
  padding:'10px 30px'
  // marginLeft:theme.spacing(4)
}));

const StyleFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  '& .MuiRadio-root': {
    color: '#EBD465', // Change this to the color you desire
  },
}));

  






const FeedbackForm = () => {
  // const classes = useStyles();
 const {token} = useParams()
 
  // const [pricingReason, setPricingReason] = useState('');
  // const [supportRole, setSupportRole] = useState('');
  // const [specify, setSpecify] = useState('');
  // const [paymentIssue, setPaymentIssue] = useState('');
  // const [changeInPlans, setChangeInPlans] = useState('');
  // const [vehiclePreferences, setVehiclePreferences] = useState('');

  const [form, setForm] = useState({
    pricingReason:"",
    supportRole:"",
    specify:"",
    paymentIssue:"",
    changeInPlans:"",
    vehiclePreferences:""
  })

  const {pricingReason, supportRole ,specify, paymentIssue,changeInPlans,vehiclePreferences} = form
  
  console.log(form)

  const HandleOnChange  = (e) =>  {
    setForm({ ...form, [e.target.name]: e.target.value }); 

  }



  


  const handleSubmit = (event) => {
    event.preventDefault();
    
    console.log('hello how are you ')
    if(pricingReason === 'Yes')
    {
      console.log(pricingReason , paymentIssue, 'YES')
      fetch(`https://newyorklimo.us/api/feedback/${token}`,
      {
       method: "post",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
        pricingReason : pricingReason , 
        paymentoptionsissue :  paymentIssue
       }),
     })
       .then((res) => res.json())
       .then((data) => {
 
           if(data.success === true)
           {
 
            //  toast.success('FeedBack Submitted', {
            //    position: "top-right",
            //    autoClose: 5000,
            //    hideProgressBar: false,
            //    closeOnClick: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    progress: undefined,
            //    theme: "dark",
            //  });
            Swal.fire(
              'Thank you for your valuable Feedback',
              '',
              'success'
            )
            window.location.replace('https://nycunitedlimo.com/');  
           }
           else if(data.success === false)
           {
             toast.error(data?.message, {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "dark",
             });
           }
 
 
 
 
 
       })
       .catch((err) => {
         console.log(err);
       });
    
    }

    if(pricingReason === 'No')
    {
      console.log(pricingReason , paymentIssue, 'YES')
      fetch(`https://newyorklimo.us/api/feedback/${token}`,
      {
       method: "post",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
        pricingReason : pricingReason , 
        supportcorrespondence :  supportRole,
        specifyissue:specify,
        cancellationchangeplans:changeInPlans,
        VehiclePreferences:vehiclePreferences
       }),
     })
       .then((res) => res.json())
       .then((data) => {
 
           if(data.success === true)
           {
 
            //  toast.success('FeedBack Submitted', {
            //    position: "top-right",
            //    autoClose: 5000,
            //    hideProgressBar: false,
            //    closeOnClick: true,
            //    pauseOnHover: true,
            //    draggable: true,
            //    progress: undefined,
            //    theme: "dark",
            //  });
            Swal.fire(
              'Thank you for your valuable Feedback',
              '',
              'success'
            )
            window.location.replace('https://nycunitedlimo.com/');
           }
           else if(data.success === false)
           {
             toast.error(data?.message, {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "dark",
             });
           }
 
 
 
 
 
       })
       .catch((err) => {
         console.log(err);
       });
    
    }


  };



   
  return (
  

      <StyledContainer maxWidth="sm">
         <img src={logo} className=' ' alt="Your Logo" width="264" height="120" />
      <Typography variant="h4" align="center" gutterBottom>
      {/* Please select from the options below: */}
      </Typography>
      <StyledForm>





      <StyleFormControl component="fieldset">
        <Typography variant="h6">Was the pricing the primary reason for your cancellation?</Typography>
          <RadioGroup
            aria-label="pricingReason"
            name="pricingReason"
            value={pricingReason}
            onChange={HandleOnChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
      </StyleFormControl>


      {pricingReason === 'Yes' ? <>
      
      <StyleFormControl component="fieldset">
          <Typography variant="h6">Were payment options an issue?</Typography>
          <RadioGroup
            aria-label="paymentIssue"
            name="paymentIssue"
            value={paymentIssue}
            onChange={HandleOnChange}
          >
            <FormControlLabel value="Limited payment methods" control={<Radio />} label="Limited payment methods" />
            <FormControlLabel value="Transaction errors or issues" control={<Radio />} label="Transaction errors or issues" />
            <FormControlLabel value="Concerns about payment security" control={<Radio />} label="Concerns about payment security" />
            <FormControlLabel value="Is pricing on the higher side" control={<Radio />} label="Is pricing on the higher side" />
        
          </RadioGroup>
          <TextField id="standard-basic" 
          name="paymentIssue"
          // value={paymentIssue}
          onChange={HandleOnChange}
          className='input_customer'
          
          label="Others"  variant="standard" />
      </StyleFormControl>     
      </> 
      
      : 
      <>




      
      {pricingReason === "No" ? <>
      <StyleFormControl component="fieldset">
          <Typography variant="h6"> Did our support and correspondence play a role in your decision?</Typography>
          <RadioGroup
            aria-label="supportRole"
            name="supportRole"
            value={supportRole}
            onChange={HandleOnChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            <TextField id="standard-basic" 
                 name="supportRole"
                //  value={supportRole}
                 onChange={HandleOnChange}
                 className='input_customer'
  
            label="Others" variant="standard" />
          </RadioGroup>
      </StyleFormControl> 
      
      </> : null }
      
 

      {supportRole === "Yes" ? <>
      <StyleFormControl component="fieldset">
          <Typography variant="h6"> Can you specify the issue you faced?</Typography>
          <RadioGroup
            aria-label="specify"
            name="specify"
            value={specify}
            onChange={HandleOnChange}
            
          >
            <FormControlLabel value="Delayed response" control={<Radio />} label="Delayed response" />
            <FormControlLabel value="Unprofessional tone" control={<Radio />} label="Unprofessional tone" />
            <FormControlLabel value="Inaccurate information provided" control={<Radio />} label="Inaccurate information provided" />
            <TextField  id="standard-basic"
                     name="specify"
                    //  value={specify}
                     onChange={HandleOnChange}
                     className='input_customer'
            label="Others" variant="standard" />
          </RadioGroup>
      </StyleFormControl> 
      
      </> :  <>
      
      {supportRole === 'No' ? <>
      
      <StyleFormControl component="fieldset">
          <Typography variant="h6">Was the cancellation because of a change in your plans?</Typography>
          <RadioGroup
            aria-label="pricingReason"
            name="changeInPlans"
            value={changeInPlans}
            onChange={HandleOnChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
           
            
           
          <TextField id="standard-basic"    name="changeInPlans"
            // value={changeInPlans}
            className='input_customer'
            onChange={HandleOnChange} label="others" variant="standard" />
          </RadioGroup>
      </StyleFormControl>
      
      </> : null }


{changeInPlans === 'No' ? <>
<StyleFormControl component="fieldset">
          <Typography variant="h6">Vehicle Preferences</Typography>
          <RadioGroup
            // aria-label="pricingReason/
            name="vehiclePreferences"
            value={vehiclePreferences}
            onChange={HandleOnChange}
          >
            <FormControlLabel value="Desired vehicle type is not available." control={<Radio />} label="Desired vehicle type is not available." />
            
           
          <TextField id="standard-basic" 
             name="vehiclePreferences"
            //  value={vehiclePreferences/}
             onChange={HandleOnChange}
             className='input_customer'
             label="others" variant="standard" />
          </RadioGroup>
      </StyleFormControl> 

</> : null }

      </>}
      
      </>
      
      }



      



       
      </StyledForm>
 <StyledSubmitButton   type="submit" variant="contained" onClick={handleSubmit} >
          Submit 
        </StyledSubmitButton>
    </StyledContainer>
  );
};

export default FeedbackForm;
